






import { Component, Vue } from 'vue-property-decorator';
import { getTerms } from '@/services/forum';

@Component
export default class Terms extends Vue {
  private termsContent = '';

  private async mounted() {
    this.termsContent = await getTerms();
  }
}
